/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface GetRootData {
  root: boolean;
  version: string;
}

export interface CreatePlanPayload {
  name: string;
  publicName: string;
  /** @default false */
  published?: boolean;
  coverImage: string | null;
  description: string | null;
  createdBy?: string | null;
  tags?: string[];
  groups?: string[];
  symbols?: {
    symbol: string;
    percentage: number;
  }[];
}

export interface CreatePlanData {
  id: string;
  name: string;
  publicName: string;
  /** @default false */
  published?: boolean;
  coverImage: string | null;
  description: string | null;
  createdBy?: string | null;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  Tags?:
    | {
        name: string;
      }[]
    | null;
  Symbols?:
    | {
        symbol: string;
        percentage: number;
      }[]
    | null;
  Groups?:
    | {
        name: string;
      }[]
    | null;
}

export type GetPlansData = {
  id: string;
  name: string;
  publicName: string;
  /** @default false */
  published?: boolean;
  coverImage: string | null;
  description: string | null;
  createdBy?: string | null;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  Tags?:
    | {
        name: string;
      }[]
    | null;
  Symbols?:
    | {
        symbol: string;
        percentage: number;
      }[]
    | null;
  Groups?:
    | {
        name: string;
      }[]
    | null;
}[];

export interface CreateTagPayload {
  name: string[];
}

export interface CreateTagData {
  ok: number;
}

export type GetTagsData = {
  name: string;
}[];

export interface CreateGroupPayload {
  name: string[];
}

export interface CreateGroupData {
  ok: number;
}

export type GetGroupsData = {
  name: string;
}[];

export interface DeletePlanData {
  ok: number;
}

export interface GetPlanByIdData {
  id: string;
  name: string;
  publicName: string;
  /** @default false */
  published?: boolean;
  coverImage: string | null;
  description: string | null;
  createdBy?: string | null;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  Tags?:
    | {
        name: string;
      }[]
    | null;
  Symbols?:
    | {
        symbol: string;
        percentage: number;
      }[]
    | null;
  Groups?:
    | {
        name: string;
      }[]
    | null;
}

export interface UpdatePlanPayload {
  name?: string;
  publicName?: string;
  /** @default false */
  published?: boolean;
  coverImage?: string | null;
  description?: string | null;
  createdBy?: string | null;
  tags?: string[];
  groups?: string[];
  symbols?: {
    symbol: string;
    percentage: number;
  }[];
}

export interface UpdatePlanData {
  id: string;
  name: string;
  publicName: string;
  /** @default false */
  published?: boolean;
  coverImage: string | null;
  description: string | null;
  createdBy?: string | null;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  Tags?:
    | {
        name: string;
      }[]
    | null;
  Symbols?:
    | {
        symbol: string;
        percentage: number;
      }[]
    | null;
  Groups?:
    | {
        name: string;
      }[]
    | null;
}

export type GetTradesData = {
  amount: number;
  volume: number;
  symbol: string;
  units: number;
  orderId?: string | null;
  positionId?: string | null;
  dealId?: string | null;
  mt_login: string;
  ticketId?: string | null;
}[];

export type GetSubscriptionsByPlanIdData = {
  id: string;
  plan_id: string;
  /** @min 25 */
  amount: number;
  user: string;
  every: {
    /**
     * @min 1
     * @max 31
     */
    day?: number;
    /** @min 1 */
    month?: number;
    /**
     * @min 0
     * @max 23
     */
    hour?: number;
  };
  /** @default "MONTHLY" */
  type?: "HOURLY" | "DAILY" | "WEEKLY" | "MONTHLY" | "ONE_TIME";
  /** @format date-time */
  next_trigger: string | null;
  /** @default "ACTIVE" */
  status?: "ACTIVE" | "WAIT_FOR_FUND" | "WAIT_FOR_CONFIRMATION" | "WAIT_FOR_EXECUTION" | "COMPLETED" | "DISABLED";
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  source: string;
  currency: string;
  mt_login: string;
  Plan: {
    id: string;
    name: string;
    publicName: string;
    /** @default false */
    published?: boolean;
    coverImage: string | null;
    description: string | null;
    createdBy?: string | null;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    updatedAt: string;
    Tags?:
      | {
          name: string;
        }[]
      | null;
    Symbols?:
      | {
          symbol: string;
          percentage: number;
        }[]
      | null;
    Groups?:
      | {
          name: string;
        }[]
      | null;
  };
  /** @default [] */
  Transactions?: {
    id: string;
    subscription_id: string;
    /** @default "PENDING" */
    status?: "PENDING" | "COMPLETED" | "FAILED" | "CANCELLED";
    flow_id?: string | null;
    error?: any;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    updatedAt: string;
    /** @default [] */
    Trades?: {
      amount: number;
      volume: number;
      symbol: string;
      units: number;
      orderId?: string | null;
      positionId?: string | null;
      dealId?: string | null;
      mt_login: string;
      ticketId?: string | null;
    }[];
  }[];
}[];

export interface JoinPlanPayload {
  every: {
    /**
     * @min 1
     * @max 31
     */
    day?: number;
    /** @min 1 */
    month?: number;
    /**
     * @min 0
     * @max 23
     */
    hour?: number;
  };
  /** @min 25 */
  amount: number;
  source: string;
  mt_login: string;
  one_time?: boolean;
}

export interface JoinPlanData {
  id: string;
  plan_id: string;
  /** @min 25 */
  amount: number;
  user: string;
  every: {
    /**
     * @min 1
     * @max 31
     */
    day?: number;
    /** @min 1 */
    month?: number;
    /**
     * @min 0
     * @max 23
     */
    hour?: number;
  };
  /** @default "MONTHLY" */
  type?: "HOURLY" | "DAILY" | "WEEKLY" | "MONTHLY" | "ONE_TIME";
  /** @format date-time */
  next_trigger: string | null;
  /** @default "ACTIVE" */
  status?: "ACTIVE" | "WAIT_FOR_FUND" | "WAIT_FOR_CONFIRMATION" | "WAIT_FOR_EXECUTION" | "COMPLETED" | "DISABLED";
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  source: string;
  currency: string;
  mt_login: string;
  Plan: {
    id: string;
    name: string;
    publicName: string;
    /** @default false */
    published?: boolean;
    coverImage: string | null;
    description: string | null;
    createdBy?: string | null;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    updatedAt: string;
    Tags?:
      | {
          name: string;
        }[]
      | null;
    Symbols?:
      | {
          symbol: string;
          percentage: number;
        }[]
      | null;
    Groups?:
      | {
          name: string;
        }[]
      | null;
  };
}

export interface UploadCoverData {
  id: string;
  name: string;
  publicName: string;
  /** @default false */
  published?: boolean;
  coverImage: string | null;
  description: string | null;
  createdBy?: string | null;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  Tags?:
    | {
        name: string;
      }[]
    | null;
  Symbols?:
    | {
        symbol: string;
        percentage: number;
      }[]
    | null;
  Groups?:
    | {
        name: string;
      }[]
    | null;
}

export type GetSubscriptionsData = {
  id: string;
  plan_id: string;
  /** @min 25 */
  amount: number;
  user: string;
  every: {
    /**
     * @min 1
     * @max 31
     */
    day?: number;
    /** @min 1 */
    month?: number;
    /**
     * @min 0
     * @max 23
     */
    hour?: number;
  };
  /** @default "MONTHLY" */
  type?: "HOURLY" | "DAILY" | "WEEKLY" | "MONTHLY" | "ONE_TIME";
  /** @format date-time */
  next_trigger: string | null;
  /** @default "ACTIVE" */
  status?: "ACTIVE" | "WAIT_FOR_FUND" | "WAIT_FOR_CONFIRMATION" | "WAIT_FOR_EXECUTION" | "COMPLETED" | "DISABLED";
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  source: string;
  currency: string;
  mt_login: string;
  Plan: {
    id: string;
    name: string;
    publicName: string;
    /** @default false */
    published?: boolean;
    coverImage: string | null;
    description: string | null;
    createdBy?: string | null;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    updatedAt: string;
    Tags?:
      | {
          name: string;
        }[]
      | null;
    Symbols?:
      | {
          symbol: string;
          percentage: number;
        }[]
      | null;
    Groups?:
      | {
          name: string;
        }[]
      | null;
  };
  /** @default [] */
  Transactions?: {
    id: string;
    subscription_id: string;
    /** @default "PENDING" */
    status?: "PENDING" | "COMPLETED" | "FAILED" | "CANCELLED";
    flow_id?: string | null;
    error?: any;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    updatedAt: string;
    /** @default [] */
    Trades?: {
      amount: number;
      volume: number;
      symbol: string;
      units: number;
      orderId?: string | null;
      positionId?: string | null;
      dealId?: string | null;
      mt_login: string;
      ticketId?: string | null;
    }[];
  }[];
}[];

export interface GetSubscriptionByIdData {
  id: string;
  plan_id: string;
  /** @min 25 */
  amount: number;
  user: string;
  every: {
    /**
     * @min 1
     * @max 31
     */
    day?: number;
    /** @min 1 */
    month?: number;
    /**
     * @min 0
     * @max 23
     */
    hour?: number;
  };
  /** @default "MONTHLY" */
  type?: "HOURLY" | "DAILY" | "WEEKLY" | "MONTHLY" | "ONE_TIME";
  /** @format date-time */
  next_trigger: string | null;
  /** @default "ACTIVE" */
  status?: "ACTIVE" | "WAIT_FOR_FUND" | "WAIT_FOR_CONFIRMATION" | "WAIT_FOR_EXECUTION" | "COMPLETED" | "DISABLED";
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  source: string;
  currency: string;
  mt_login: string;
  Plan: {
    id: string;
    name: string;
    publicName: string;
    /** @default false */
    published?: boolean;
    coverImage: string | null;
    description: string | null;
    createdBy?: string | null;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    updatedAt: string;
    Tags?:
      | {
          name: string;
        }[]
      | null;
    Symbols?:
      | {
          symbol: string;
          percentage: number;
        }[]
      | null;
    Groups?:
      | {
          name: string;
        }[]
      | null;
  };
  /** @default [] */
  Transactions?: {
    id: string;
    subscription_id: string;
    /** @default "PENDING" */
    status?: "PENDING" | "COMPLETED" | "FAILED" | "CANCELLED";
    flow_id?: string | null;
    error?: any;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    updatedAt: string;
    /** @default [] */
    Trades?: {
      amount: number;
      volume: number;
      symbol: string;
      units: number;
      orderId?: string | null;
      positionId?: string | null;
      dealId?: string | null;
      mt_login: string;
      ticketId?: string | null;
    }[];
  }[];
}

export interface UpdateSubscriptionPayload {
  amount: number;
}

export interface UpdateSubscriptionData {
  id: string;
  plan_id: string;
  /** @min 25 */
  amount: number;
  user: string;
  every: {
    /**
     * @min 1
     * @max 31
     */
    day?: number;
    /** @min 1 */
    month?: number;
    /**
     * @min 0
     * @max 23
     */
    hour?: number;
  };
  /** @default "MONTHLY" */
  type?: "HOURLY" | "DAILY" | "WEEKLY" | "MONTHLY" | "ONE_TIME";
  /** @format date-time */
  next_trigger: string | null;
  /** @default "ACTIVE" */
  status?: "ACTIVE" | "WAIT_FOR_FUND" | "WAIT_FOR_CONFIRMATION" | "WAIT_FOR_EXECUTION" | "COMPLETED" | "DISABLED";
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  source: string;
  currency: string;
  mt_login: string;
  Plan: {
    id: string;
    name: string;
    publicName: string;
    /** @default false */
    published?: boolean;
    coverImage: string | null;
    description: string | null;
    createdBy?: string | null;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    updatedAt: string;
    Tags?:
      | {
          name: string;
        }[]
      | null;
    Symbols?:
      | {
          symbol: string;
          percentage: number;
        }[]
      | null;
    Groups?:
      | {
          name: string;
        }[]
      | null;
  };
}

export interface UnsubscribePlanData {
  message: string;
}

export type OnMoneyReceivedPayload = any;

export type OnMoneyReceivedData = any;

export type OnFailMoneyPayload = any;

export interface OnFailMoneyData {
  message: string;
}

export interface ResubscribeData {
  id: string;
  plan_id: string;
  /** @min 25 */
  amount: number;
  user: string;
  every: {
    /**
     * @min 1
     * @max 31
     */
    day?: number;
    /** @min 1 */
    month?: number;
    /**
     * @min 0
     * @max 23
     */
    hour?: number;
  };
  /** @default "MONTHLY" */
  type?: "HOURLY" | "DAILY" | "WEEKLY" | "MONTHLY" | "ONE_TIME";
  /** @format date-time */
  next_trigger: string | null;
  /** @default "ACTIVE" */
  status?: "ACTIVE" | "WAIT_FOR_FUND" | "WAIT_FOR_CONFIRMATION" | "WAIT_FOR_EXECUTION" | "COMPLETED" | "DISABLED";
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  source: string;
  currency: string;
  mt_login: string;
  Plan: {
    id: string;
    name: string;
    publicName: string;
    /** @default false */
    published?: boolean;
    coverImage: string | null;
    description: string | null;
    createdBy?: string | null;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    updatedAt: string;
    Tags?:
      | {
          name: string;
        }[]
      | null;
    Symbols?:
      | {
          symbol: string;
          percentage: number;
        }[]
      | null;
    Groups?:
      | {
          name: string;
        }[]
      | null;
  };
}

export interface GetTransactionByIdData {
  id: string;
  subscription_id: string;
  /** @default "PENDING" */
  status?: "PENDING" | "COMPLETED" | "FAILED" | "CANCELLED";
  flow_id?: string | null;
  error?: any;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @default [] */
  Trades?: {
    amount: number;
    volume: number;
    units: number;
    symbol: string;
    orderId?: string | null;
    positionId?: string | null;
    dealId?: string | null;
    mt_login: string;
    ticketId?: string | null;
  }[];
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input;
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Amana Backoffice Api
 * @version 1.0.0
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags root
   * @name GetRoot
   * @request GET:/
   */
  getRoot = (params: RequestParams = {}) =>
    this.request<GetRootData, any>({
      path: `/`,
      method: "GET",
      format: "json",
      ...params,
    });

  v1 = {
    /**
     * No description
     *
     * @tags plans
     * @name CreatePlan
     * @request POST:/v1/plans/
     * @secure
     */
    createPlan: (data: CreatePlanPayload, params: RequestParams = {}) =>
      this.request<CreatePlanData, any>({
        path: `/v1/plans/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags plans
     * @name GetPlans
     * @request GET:/v1/plans/
     * @secure
     */
    getPlans: (params: RequestParams = {}) =>
      this.request<GetPlansData, any>({
        path: `/v1/plans/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags plans
     * @name CreateTag
     * @request POST:/v1/plans/tags
     * @secure
     */
    createTag: (data: CreateTagPayload, params: RequestParams = {}) =>
      this.request<CreateTagData, any>({
        path: `/v1/plans/tags`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags tags
     * @name GetTags
     * @request GET:/v1/plans/tags
     * @secure
     */
    getTags: (params: RequestParams = {}) =>
      this.request<GetTagsData, any>({
        path: `/v1/plans/tags`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags plans
     * @name CreateGroup
     * @request POST:/v1/plans/groups
     * @secure
     */
    createGroup: (data: CreateGroupPayload, params: RequestParams = {}) =>
      this.request<CreateGroupData, any>({
        path: `/v1/plans/groups`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags groups
     * @name GetGroups
     * @request GET:/v1/plans/groups
     * @secure
     */
    getGroups: (params: RequestParams = {}) =>
      this.request<GetGroupsData, any>({
        path: `/v1/plans/groups`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags plans
     * @name DeletePlan
     * @request DELETE:/v1/plans/{id}
     * @secure
     */
    deletePlan: (id: string, params: RequestParams = {}) =>
      this.request<DeletePlanData, any>({
        path: `/v1/plans/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags plans
     * @name GetPlanById
     * @request GET:/v1/plans/{id}
     * @secure
     */
    getPlanById: (id: string, params: RequestParams = {}) =>
      this.request<GetPlanByIdData, any>({
        path: `/v1/plans/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags plans
     * @name UpdatePlan
     * @request PUT:/v1/plans/{id}
     * @secure
     */
    updatePlan: (id: string, data: UpdatePlanPayload, params: RequestParams = {}) =>
      this.request<UpdatePlanData, any>({
        path: `/v1/plans/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags plans
     * @name GetTrades
     * @request GET:/v1/plans/trades
     * @secure
     */
    getTrades: (params: RequestParams = {}) =>
      this.request<GetTradesData, any>({
        path: `/v1/plans/trades`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags plans, subscription
     * @name GetSubscriptionsByPlanId
     * @request GET:/v1/plans/{id}/subscriptions
     * @secure
     */
    getSubscriptionsByPlanId: (id: string, params: RequestParams = {}) =>
      this.request<
        GetSubscriptionsByPlanIdData,
        {
          message: string;
        }
      >({
        path: `/v1/plans/${id}/subscriptions`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags subscription
     * @name JoinPlan
     * @request POST:/v1/plans/{id}/subscribe
     * @secure
     */
    joinPlan: (id: string, data: JoinPlanPayload, params: RequestParams = {}) =>
      this.request<
        JoinPlanData,
        {
          message: string;
          issues?: Record<string, string[]>;
        }
      >({
        path: `/v1/plans/${id}/subscribe`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags plans
     * @name UploadCover
     * @request PUT:/v1/plans/{id}/upload
     * @secure
     */
    uploadCover: (id: string, params: RequestParams = {}) =>
      this.request<
        UploadCoverData,
        {
          message: string;
        }
      >({
        path: `/v1/plans/${id}/upload`,
        method: "PUT",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags subscription
     * @name GetSubscriptions
     * @request GET:/v1/subscriptions/
     * @secure
     */
    getSubscriptions: (params: RequestParams = {}) =>
      this.request<GetSubscriptionsData, any>({
        path: `/v1/subscriptions/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags subscription
     * @name GetSubscriptionById
     * @request GET:/v1/subscriptions/{id}
     * @secure
     */
    getSubscriptionById: (id: string, params: RequestParams = {}) =>
      this.request<GetSubscriptionByIdData, any>({
        path: `/v1/subscriptions/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags subscription
     * @name UpdateSubscription
     * @request PUT:/v1/subscriptions/{id}
     * @secure
     */
    updateSubscription: (id: string, data: UpdateSubscriptionPayload, params: RequestParams = {}) =>
      this.request<
        UpdateSubscriptionData,
        {
          message: string;
          issues?: Record<string, string[]>;
        }
      >({
        path: `/v1/subscriptions/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags subscription
     * @name UnsubscribePlan
     * @request DELETE:/v1/subscriptions/{id}
     * @secure
     */
    unsubscribePlan: (id: string, params: RequestParams = {}) =>
      this.request<
        UnsubscribePlanData,
        {
          message: string;
        }
      >({
        path: `/v1/subscriptions/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags subscription
     * @name OnMoneyReceived
     * @request POST:/v1/subscriptions/hooks/onSuccess/{id}
     */
    onMoneyReceived: (id: string, data: OnMoneyReceivedPayload, params: RequestParams = {}) =>
      this.request<OnMoneyReceivedData, any>({
        path: `/v1/subscriptions/hooks/onSuccess/${id}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags subscription
     * @name OnFailMoney
     * @request POST:/v1/subscriptions/hooks/onFail/{id}
     */
    onFailMoney: (id: string, data: OnFailMoneyPayload, params: RequestParams = {}) =>
      this.request<OnFailMoneyData, any>({
        path: `/v1/subscriptions/hooks/onFail/${id}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags subscription
     * @name Resubscribe
     * @request POST:/v1/subscriptions/{id}/reactivate
     * @secure
     */
    resubscribe: (id: string, params: RequestParams = {}) =>
      this.request<
        ResubscribeData,
        {
          message: string;
          issues?: Record<string, string[]>;
        }
      >({
        path: `/v1/subscriptions/${id}/reactivate`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags transactions
     * @name GetTransactionById
     * @request GET:/v1/transactions/{id}
     * @secure
     */
    getTransactionById: (id: string, params: RequestParams = {}) =>
      this.request<GetTransactionByIdData, any>({
        path: `/v1/transactions/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
